// @flow

import React from 'react';
import { observer } from 'mobx-react';
import { observable, action, computed } from 'mobx';
import ReactResizeDetector from 'react-resize-detector';
import * as classNames from 'classnames';

import { JSON_HOME } from '../../../constants/json';
import styles from './style.module.scss';
import JsonStore from '../../../stores/json-store';
import ParallaxStore from '../../../stores/home/parallax-store';
import BlurUpImage from '../../../components/BlurUpImage';
import getImageUrl from "../../../utils/get-image-url";
import filter from "lodash/filter";
import UiStore from "../../../stores/ui-store";

type SceneThreeProps = {
  parallax: ParallaxStore,
  json: JsonStore,
  ui: UiStore,
}

@observer
class SceneThree extends React.Component<SceneThreeProps, any> {

  @computed get containerPos(): number {
    // const end: number = this.props.ui.windowWidth < 600 ? 100 : 200;
    const progress: number = this.props.parallax.sceneProgress[2];
    if (progress === 0) return 0;
    return 0;
  }

  @computed get itemPos(): number {
    // const progress: number = this.props.parallax.sceneProgress[2];
    // return 20 - (40 * progress);
    return 0;
  }

  @observable images: string[] = [];

  @action
  setImages = (images: string[]): void => {
    this.images = images;
  };

  handleHeightResize = (width: number, height: number) => {
    if (height !== 0) this.props.parallax.setSceneHeight(2, height);
  };

  componentDidMount(): void {
    // fetch(`https://www.instagram.com/stagecoach/?__a=1`)
    //   .then(data => {
    //     return data.json();
    //   })
    //   .then(data => {
    //     if (data && data.graphql && data.graphql.user) {
    //       const items = data.graphql.user.edge_owner_to_timeline_media.edges;
    //       this.setImages([items[0].node.display_url, items[1].node.display_url, items[2].node.display_url, items[3].node.display_url])
    //     }
    //   }).catch(e => {
    //     console.log(e);
    //   });
  }

  render() {

    const mobile: boolean = this.props.ui.windowWidth !== 0 && this.props.ui.windowWidth < 700;
    const data: any = this.props.json.store.get(JSON_HOME);
    const homeData: any = data[JSON_HOME];

    return (
      <>
        <div className={styles.container}
          style={{
            transform: mobile ? 'none' : `translateY(${this.containerPos}px)`
          }}>
          <BlurUpImage
            repeat={true}
            size="auto"
            src={getImageUrl(filter(homeData.images, { title: 'paper' }))}
          />
          <div className={styles.linksWrapper}>
            <div className={styles.linksHeader}>
              <h2>Sunshine On Its Way</h2>
            </div>
            <div className={styles.links}>
              {this.images.length === 0 ? homeData.instagram.map((item, index) => {
                const imgUrl = getImageUrl(item);

                return (
                  <a key={`${index}sceneThreeButton`}
                    href={`https://www.instagram.com/stagecoach/`}
                    target={`_blank`}
                    rel={`noopener noreferrer`}
                    className={classNames({
                      [styles.button]: true
                    })}>
                    <div className={styles.ratio}>
                      <BlurUpImage
                        src={imgUrl} />
                    </div>
                  </a>
                )
              }) : this.images.map((item, index) => {
                return (
                  <a key={`${index}sceneThreeButton`}
                    href={`https://www.instagram.com/stagecoach/`}
                    target={`_blank`}
                    rel={`noopener noreferrer`}
                    className={classNames({
                      [styles.button]: true
                    })}>
                    <div className={styles.ratio}>
                      <img
                        src={item} />
                    </div>
                  </a>
                )
              })}
            </div>
          </div>
          <ReactResizeDetector handleHeight onResize={this.handleHeightResize} />
        </div>
      </>
    )
  }
}

export default SceneThree;
