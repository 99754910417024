// @flow

import React from 'react';
import { observer } from 'mobx-react';
import UiStore from '../../../stores/ui-store';

import styles from './style.module.scss';

type DayNightProps = {
  parallax: UiStore,
  day: any,
  night: any,
  transitionTime?: number,
  noTransitionOut?: boolean,
}

@observer
class DayNight extends React.Component<DayNightProps, any> {

  render() {

    return (
      <>
        <div className={styles.wrapper}
             style={{
                transition: this.props.noTransitionOut && !this.props.parallax.isDay ? 'none' :
                  `opacity ${this.props.transitionTime || 950}ms linear`,
                opacity: this.props.parallax.isDay ? 1 : 0
              }}>
          {this.props.parallax.isDayRendered && this.props.day}
        </div>
        <div className={styles.wrapper}
             style={{
               transition: this.props.noTransitionOut && this.props.parallax.isDay ? 'none' :
                 `opacity ${this.props.transitionTime || 950}ms linear`,
                opacity: this.props.parallax.isDay ? 0 : 1
              }}>
          {this.props.parallax.isNightRendered && this.props.night}
        </div>
      </>
    )
  }
}

export default DayNight;
