// @flow

import React from 'react';
import { observer } from 'mobx-react';
import {action, computed, observable} from 'mobx';
import ReactResizeDetector from 'react-resize-detector';
import filter from 'lodash/filter';
import getImageUrl from '../../../utils/get-image-url';
import * as classNames from 'classnames';
// import * as classNames from 'classnames';

import { JSON_HOME } from '../../../constants/json';
import styles from './style.module.scss';
import JsonStore from '../../../stores/json-store';
import UiStore from '../../../stores/ui-store';
import OverlayStore from '../../../stores/overlay-store';
import ParallaxStore from '../../../stores/home/parallax-store';
// import BelowScene from '../BelowScene';
import BlurUpImage from '../../../components/BlurUpImage';
import Button from '../../../components/Button';
import Markdown from '../../../components/Markdown';
import DayNight from "../DayNight";

type SceneOneProps = {
  parallax: ParallaxStore,
  json: JsonStore,
  ui: UiStore,
  overlay: OverlayStore,
}

@observer
class SceneOne extends React.Component<SceneOneProps, any> {

  componentDidMount() {
    if (window) {
      setTimeout(() => this.setLoaded(true), 300);
    }
  }

  @observable loaded: boolean = false;

  @action
  setLoaded = (loaded: boolean): void => {
    this.loaded = loaded;
  };

  @computed get containerPos(): number {
    const end: number = this.props.ui.windowWidth < 600 ? 100 : 200;
    const progress: number = this.props.parallax.sceneProgress[0];
    if (progress === 0) return 0;
    return -(progress * end);
  }

  @computed get itemPos(): number {
    const end: number = (this.props.ui.windowWidth !== 0 && this.props.ui.windowWidth < 600) ? 60 : 60;
    const progress: number = this.props.parallax.sceneProgress[0];
    if (progress === 0) return 0;
    return -(progress * end);
  }

  @computed get ctaPos(): number {
    const end: number = 150;
    const progress: number = this.props.parallax.sceneProgress[0];
    if (progress === 0) return 0;
    return -(progress * end);
  }

  @computed get posterScale(): number {
    if (this.props.ui.windowHeight > this.props.ui.windowWidth) return 1;
    const width: number = this.props.ui.windowWidth * 0.4 * 1.3;
    const maxHeight: number = this.props.ui.windowHeight - 120;
    if (width <= maxHeight) return 1;
    else return maxHeight / width;
  }

  @computed get posterMargin(): string {
    const margin = this.props.ui.windowWidth < 700 ? 60 : 60;
    return `${margin / this.posterScale}px`;
  }

  @observable store: Map<string, any> = new Map();

  @action
  setJson = (json: any, key: string): void => {
    this.store.set(key, json);
  };

  handleHeightResize = (width: number, height: number) => {
    if (height !== 0) this.props.parallax.setSceneHeight(0, height);
  };

  render() {

    const data: any = this.props.json.store.get(JSON_HOME);
    const homeData: any = data[JSON_HOME];
    // const portrait: boolean = this.props.ui.windowHeight > this.props.ui.windowWidth;
    const mobile: boolean = this.props.ui.windowWidth !== 0 && this.props.ui.windowWidth < 700;

    return (
      <>
        <div className={styles.container}>
          {!mobile && <>
            <DayNight parallax={this.props.ui}
                      day={<BlurUpImage
                        src={getImageUrl(mobile ? filter(homeData.images, {title: 'bg'}) :
                          filter(homeData.images, {title: 'bg'}))}/>}
                      night={<BlurUpImage
                        src={getImageUrl(mobile ? filter(homeData.imagesNight, {title: 'bg'}) :
                          filter(homeData.imagesNight, {title: 'bg'}))}/>}/>
            <div className={styles.palmsLeft}
                 style={{
                   transformOrigin: `0 0 0`,
                   transform: mobile ? 'none' : `scale(${1 - (this.itemPos / 100)}, ${1 - (this.itemPos / 100)})`
                 }}>
              <DayNight parallax={this.props.ui}
                        day={<BlurUpImage
                          useImage
                          src={getImageUrl(mobile ? filter(homeData.images, {title: 'trees-left'}) :
                            filter(homeData.images, {title: 'trees-left'}))}/>}
                        night={<BlurUpImage
                          useImage
                          src={getImageUrl(mobile ? filter(homeData.imagesNight, {title: 'trees-left'}) :
                            filter(homeData.imagesNight, {title: 'trees-left'}))}/>}/>
            </div>
            <div className={styles.palmsRight}
                 style={{
                   transformOrigin: `100% 0 0`,
                   transform: mobile ? 'none' : `scale(${1 - (this.itemPos / 70)}, ${1 - (this.itemPos / 70)})`
                 }}>
              <DayNight parallax={this.props.ui}
                        day={<BlurUpImage
                          useImage
                          src={getImageUrl(mobile ? filter(homeData.images, {title: 'trees-right'}) :
                            filter(homeData.images, {title: 'trees-right'}))}/>}
                        night={<BlurUpImage
                          useImage
                          src={getImageUrl(mobile ? filter(homeData.imagesNight, {title: 'trees-right'}) :
                            filter(homeData.imagesNight, {title: 'trees-right'}))}/>}/>
            </div>
            <div className={styles.sign}
                 style={{
                   transformOrigin: `20% 0 0`,
                   transform: mobile ? 'none' : `scale(${1 - (this.itemPos / 100)}, ${1 - (this.itemPos / 100)})`
                 }}>
              <DayNight parallax={this.props.ui}
                        day={<BlurUpImage
                          useImage
                          src={getImageUrl(mobile ? filter(homeData.images, {title: 'sign'}) :
                            filter(homeData.images, {title: 'sign'}))}/>}
                        night={<BlurUpImage
                          useImage
                          src={getImageUrl(mobile ? filter(homeData.imagesNight, {title: 'sign'}) :
                            filter(homeData.imagesNight, {title: 'sign'}))}/>}/>
            </div>
          </>}
          <div className={styles.cta}
               style={{
                 transform: mobile ? 'none' : `translateY(${this.ctaPos}%)`
               }}>
            {mobile && <DayNight parallax={this.props.ui}
                                 day={<BlurUpImage
                                   src={getImageUrl(mobile ? filter(homeData.images, {title: 'bg'}) :
                                     filter(homeData.images, {title: 'bg'}))}/>}
                                 night={<BlurUpImage
                                   src={getImageUrl(mobile ? filter(homeData.imagesNight, {title: 'bg'}) :
                                     filter(homeData.imagesNight, {title: 'bg'}))}/>}/>}
            <div className={classNames({
              [styles.content]: true,
              [styles.night]: !this.props.ui.isDay
            })}>
              <Markdown markdown={homeData.blurb}
                        centerContent />
              <div className={styles.buttons}>
                {homeData.buttons.map((item, index) => {
                  return (
                    <div key={`${index}homeCTAButton`}
                         className={styles.button}
                         style={{
                           width: `${item.width}px`
                         }}>
                      <Button type={item.type}
                              url={item.url}
                              external={item.externalUrl || (item.externalUrlLong && item.externalUrlLong.text) || item.externalUrlLong}
                              display={item.display}/>
                    </div>
                  )
                })}
              </div>
              {mobile && <>
                <div className={styles.dateCta}>
                  <DayNight parallax={this.props.ui}
                            day={<BlurUpImage
                              useImage
                              src={getImageUrl(mobile ? filter(homeData.images, {title: 'date'}) :
                                filter(homeData.images, {title: 'date'}))}/>}
                            night={<BlurUpImage
                              useImage
                              src={getImageUrl(mobile ? filter(homeData.imagesNight, {title: 'date'}) :
                                filter(homeData.imagesNight, {title: 'date'}))}/>}/>
                </div>
                <div className={styles.signCta}>
                  <DayNight parallax={this.props.ui}
                            day={<BlurUpImage
                              useImage
                              src={getImageUrl(mobile ? filter(homeData.images, {title: 'sign'}) :
                                filter(homeData.images, {title: 'sign'}))}/>}
                            night={<BlurUpImage
                              useImage
                              src={getImageUrl(mobile ? filter(homeData.imagesNight, {title: 'sign'}) :
                                filter(homeData.imagesNight, {title: 'sign'}))}/>}/>
                </div>
              </>}
            </div>
          </div>
          <div className={styles.poster}
               style={{
                 opacity: this.loaded ? 1 : 0,
                 transform: mobile ? 'none' : `translateY(${this.itemPos}%)`
               }}>
            <div className={styles.ratio}>
              <div className={styles.wrapper}
                   onClick={() => {
                     this.props.overlay.setPosterActive(true);
                     this.props.overlay.setPosterUrl(getImageUrl(homeData.poster));
                   }}
                   style={{
                     transform: `scale(${this.posterScale})`
                   }}>
                <BlurUpImage
                  src={getImageUrl(filter(homeData.images, {title: 'poster-bg'}))}/>
                <div className={styles.image}
                     style={{
                       top: this.posterMargin,
                       left: this.posterMargin,
                       bottom: this.posterMargin,
                       right: this.posterMargin
                     }}>
                  <BlurUpImage
                    src={getImageUrl(homeData.poster)} />
                  <div className={styles.mag}>
                    <svg version="1.1"
                         x="0px"
                         y="0px"
                         viewBox="0 0 60 60">
                      <path fill="#ffffff"
                            d="M59.272,55.735L40.103,36.47C43.16,32.63,45,27.777,45,22.5 C45,10.093,34.907,0,22.5,0S0,10.093,0,22.5C0,34.905,10.093,45,22.5,45c5.328,0,10.223-1.873,14.082-4.979l19.146,19.243 c0.972,0.979,2.552,0.983,3.536,0.007C60.242,58.297,60.244,56.713,59.272,55.735z M22.5,40C12.85,40,5,32.148,5,22.5 C5,12.849,12.85,5,22.5,5C32.148,5,40,12.849,40,22.5C40,32.148,32.148,40,22.5,40z M32.5,20H25v-7.497 C25,11.12,23.936,10,22.554,10s-2.5,1.118-2.5,2.498L20.029,20H12.5c-1.382,0-2.5,1.118-2.5,2.5c0,1.38,1.118,2.5,2.5,2.5h7.524 L20,32.497c-0.003,1.38,1.115,2.5,2.495,2.503h0.002c1.38,0,2.5-1.118,2.503-2.497V25h7.5c1.38,0,2.5-1.12,2.5-2.5 C35,21.118,33.88,20,32.5,20z"/>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ReactResizeDetector handleHeight onResize={this.handleHeightResize} />
          <div
            className={styles.date}
            style={{
              opacity: this.loaded ? 1 : 0,
              transform: mobile ? 'none' : `translateY(${this.itemPos}%) scale(${1 + -this.itemPos / 200})`
            }}
          >
            <div className={styles.dateInner}>
              <DayNight parallax={this.props.ui}
                        day={<BlurUpImage
                          useImage
                          src={getImageUrl(mobile ? filter(homeData.images, {title: 'date'}) :
                            filter(homeData.images, {title: 'date'}))}/>}
                        night={<BlurUpImage
                          useImage
                          src={getImageUrl(mobile ? filter(homeData.imagesNight, {title: 'date'}) :
                            filter(homeData.imagesNight, {title: 'date'}))}/>}/>
            </div>
          </div>
        </div>
        {/* <BelowScene parallax={this.props.parallax}
                    bg={getImageUrl(filter(homeData.images, {title: 'top-bg'}))}
                    offset={250}
                    index={0}>
          <div className={styles.below}>
            <div className={styles.center}>
              <div className={styles.logo}>
                <img src={getImageUrl(filter(homeData.images, {title: 'welcome-to-stagecoach'}))} />
              </div>
            </div>
            <div className={styles.center}>
              <div className={styles.embroidery}>
                <BlurUpImage src={getImageUrl(filter(homeData.images, {title: 'embroidery'}))} />
              </div>
            </div>
            <div className={styles.center}>
              <div className={styles.items}>
                <BlurUpImage src={getImageUrl(filter(homeData.images, {title: 'items-top'}))} />
              </div>
            </div>
          </div>
        </BelowScene> */}
      </>
    )
  }
}

export default SceneOne;
