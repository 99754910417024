// @flow

import React from 'react';
import { observer, inject } from 'mobx-react';
import { graphql } from 'gatsby';
import { Provider } from 'mobx-react';

import { STORE_JSON, STORE_UI, STORE_HOME_PARALLAX, STORE_OVERLAY } from '../constants/stores';
import { JSON_HOME } from '../constants/json';
import JsonStore from '../stores/json-store';
import UiStore from '../stores/ui-store';
import OverlayStore from '../stores/overlay-store';
import ParallaxStore from '../stores/home/parallax-store';
import SceneOne from '../components/Parallax/SceneOne';
// import SceneTwo from '../components/Parallax/SceneTwo';
import SceneThree from '../components/Parallax/SceneThree';
import Metadata from "../components/Metadata";


type IndexProps = {
  json: JsonStore,
  ui: UiStore,
  overlay: OverlayStore,
  data: any,
  location: any,
}

@inject(STORE_JSON, STORE_UI, STORE_OVERLAY)
@observer
class IndexPage extends React.Component<IndexProps, any> {

  stores: any;

  constructor(props: IndexProps, context: any) {
    super(props, context);
    this.props[STORE_JSON].setJsonFromGraphQL(this.props.data, JSON_HOME);
    if (this.props.ui) {
      this.stores = {
        [STORE_HOME_PARALLAX]: new ParallaxStore(this.props.ui),
      }
    }
  }

  componentDidMount() {
    if (process.env.GATSBY_PREVIEW === 'true' && window) {
      this.props[STORE_JSON].getData(JSON_HOME, {
        contentType: 'home',
        id: '27X60kiqlWeOWY8Eg02U4U'
      });
    }
    // if (window) {
    //   const hour = new Date().getHours();
    //   this.props.ui.setIsDay(!(hour <= 5 || hour >= 18));
    // }
  }

  render() {
    console.log('Stagecoach 2021');
    return (
      <Provider {...this.stores}>
        <>
          <Metadata data={this.props.data.home.metadata}
            location={this.props.location} />
          <SceneOne
            overlay={this.props.overlay}
            json={this.props.json}
            ui={this.props.ui}
            parallax={this.stores[STORE_HOME_PARALLAX]} />
          {/* <SceneTwo
            json={this.props.json}
            ui={this.props.ui}
            parallax={this.stores[STORE_HOME_PARALLAX]}/> */}
          <SceneThree
            json={this.props.json}
            ui={this.props.ui}
            parallax={this.stores[STORE_HOME_PARALLAX]} />
        </>
      </Provider>
    )
  }
}

export const query = graphql`
query {
 home: contentfulHome(contentful_id:{eq:"27X60kiqlWeOWY8Eg02U4U"}) {
    metadata {
      title
      description
      image {
        file {
          url
        }
      }
      ogTitle
      ogDescription
      twitterCard
    }
    blurb {
      markdown: blurb
    }
    buttons {
      width
      display
      url
      externalUrl
      type
    }
    poster {
      file {
        url
      }
    }
    images {
      title
      file {
        url
        details {
          image {
            height
            width
          }
        }
      }
    }
    instagram {
      file {
        url
        details {
          image {
            height
            width
          }
        }
      }
    }
    imagesNight {
      title
      file {
        url
        details {
          image {
            height
            width
          }
        }
      }
    }
    secondSectionHeaderTop
    secondSectionHeaderBottom
    sceneTwoButtons {
      type
      display
      url
      externalUrl
      type
      image {
        file {
          url
        }
      }
    }
    thirdSectionHeaderTop
    thirdSectionHeaderBottom
    sceneThreeButtons {
      type
      display
      url
      externalUrl
      type
      image {
        file {
          url
        }
      }
    }
  }
}`;

export default IndexPage;
