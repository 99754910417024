// @flow

import { observable, action, computed } from 'mobx';
import reduce from 'lodash/reduce';

import UiStore from '../ui-store';

class ParallaxStore {

  uiStore: UiStore;

  constructor(uiStore: UiStore) {
    this.uiStore = uiStore;
  }

  @observable isDay: boolean = true;

  @action
  setIsDay = (isDay: boolean): void => {
    this.isDay = isDay;
  };

  @observable isDayRendered: boolean = true;

  @action
  setIsDayRendered = (rendered: boolean): void => {
    this.isDayRendered = rendered;
  };

  @observable isNightRendered: boolean = true;

  @action
  setIsNightRendered = (rendered: boolean): void => {
    this.isNightRendered = rendered;
  };

  @observable sceneHeights: number[] = [0, 0, 0];

  @action
  setSceneHeight = (index: number, height: number): void => {
    this.sceneHeights[index] = height;
  };

  @observable belowSceneHeights: number[] = [0, 0, 0];

  @action
  setBelowSceneHeight = (index: number, height: number): void => {
    this.belowSceneHeights[index] = height;
  };

  @observable sceneEnds: number[] = [0, 0, 0];

  @action
  setSceneEnds = (index: number, height: number): void => {
    this.sceneEnds[index] = height;
  };

  @computed get sceneStarts(): {
    start: number,
    end: number
  }[] {
    let totalHeight: number = 0;
    return reduce(this.sceneHeights, (acc: {
      start: number,
      end: number
    }[], height: number, index: number) => {
      acc.push({
        start: totalHeight,
        end: (index === 0) ? totalHeight + height : totalHeight + (height * 2)
      });

      if (index === 0) {
        const skew = height - this.uiStore.windowHeight;
        totalHeight += this.belowSceneHeights[index] + (skew > 0 ? skew : 0);
      }
      else totalHeight += (this.sceneHeights[index - 1] + this.belowSceneHeights[index]);
      return acc;
    }, [])
  }

  @computed get sceneProgress(): number[] {
    const pos: number = this.uiStore.windowScrollY;
    return reduce(this.sceneStarts, (acc: number[], start: {
      start: number,
      end: number
    }) => {
      if (pos <= start.start) acc.push(0);
      else if (pos >= start.end) acc.push(1);
      else acc.push((pos - start.start) / (start.end - start.start));
      return acc;
    }, [])
  }
}

export default ParallaxStore;
